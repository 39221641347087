import { useMsal } from "@azure/msal-react";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { Alert, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { sendMail } from "../authConfig";
import { OdesliMail } from "../services/MailService";
import EmailDialog from "./EmailDialog";

export default function Email(props) { 
    let [emailDialogOpen,setEmailDialogOpen] = useState(false);
    let [odeslatEmail,setOdeslatEmail] =  useState(false);
    let [status,setStatus] = useState(0);
    const { instance,accounts } = useMsal();
    const email = accounts[0].username;
    const request = {
        ...sendMail,
        account: accounts[0]
      };

      
      const handleSendEmail = () => {
          setEmailDialogOpen(true);
        }
        
        useEffect(() => {
            if (odeslatEmail) {
                odeslatEmailCentrala();
            }
            setOdeslatEmail(false);
        },[odeslatEmail]);
        
        
        const odeslatEmailCentrala = () => {
            let currentDate = new Date();  
            let datum = currentDate.getDate()+'.'+(currentDate.getMonth()+1)+'.'+currentDate.getFullYear()+' '+('0'+currentDate.getHours()).slice(-2)+':'+('0'+currentDate.getMinutes()).slice(-2)+':'+('0'+currentDate.getSeconds()).slice(-2);
            const Zprava = {
                message: {
                    subject: 'Nové dokumenty u smlouvy '+props.cislols,
                    body: {
                        contentType: 'HTML',
                        content: '<b><span style="font-size:15.0pt;color: #003883;">'+'Nové dokumenty na serveru u smlouvy '+props.cislols+'</span></b><br/>'+
                         '<span style="font-size:15.0pt"></span><br/>'+
                         'Datum generování: ' + datum + '<br/>'+
                         'Smlouva: <b>' + props.cislols + '</b><br/>'+
                         '<br/>'+
                         '<div style="color:#003883">'+'Na server byly uloženy nové dokumenty u smlouvy <strong>' + props.cislols + '</strong> do složky <strong>KZaplacení</strong>.'+'</div>'+
                         '<br/><br/>'+
                         '<hr size="1" width="100%" noshade="" style="color:#F39800" align="left">'+
                         '<p style="margin: 0px; padding: 0px;"><em>'+
                         'Veškeré informace jsou generované výhradně pro interní účely UNILEASING a.s. a nelze je přeposílat jiným subjektům. Generováno aplikací Webunikalk 1.0</em></p>'
                    },
                    toRecipients: [
                    {
                    emailAddress: {
                      address: "hostalkova@unileasing.cz"
                    }
                    },
                    {
                    emailAddress: {
                      address: "pechanova@unileasing.cz"
                    }
                    },  
                    {
                    emailAddress: {
                      address: "rubesova@unileasing.cz"
                    }
                    },                                   

                  // {
                  //   emailAddress: {
                  //     address: "krahulec@unileasing.cz"
                  //   }
                  // },
            ],
            bccRecipients: [
                {
                    emailAddress: {
                      address: "logger@unileasing.cz"
                    }
                },   
                {
                  emailAddress: {
                    address: email
                  }
              },                                 
            ],            
            },
        saveToSentItems: 'true'
        };

        instance.acquireTokenSilent(request).then((response) => {
            OdesliMail(response.accessToken,email,Zprava).then(response => {
                setStatus(response.status);
            }); 
          }).catch((e) => {	  	
            instance.acquireTokenPopup(request).then((response) => {
                OdesliMail(response.accessToken,email,Zprava).then(response => {
                    setStatus(response.status);
                });  
            });
          });
      }  

    return(
        <div>
            {status === 202 ? <Alert severity="success">Email úspěšně odeslán na centrálu!</Alert>:<p></p>}
            { status > 0 && status !== 202 ? <Alert severity="error">Problém s odesláním emailu na centrálu. Kontaktuje IT oddělení.</Alert>:<p></p>}
            <Button onClick={handleSendEmail} component="label" variant="contained" startIcon={<EmailOutlinedIcon sx={{color:'white'}} />} sx={{fontSize: '11px',backgroundColor:'#dd9234', borderColor:'#dd9234',color:'white' }}>Informovat centrálu o platbě</Button>
            <EmailDialog emailDialogOpen={emailDialogOpen} setEmailDialogOpen={setEmailDialogOpen} setOdeslatEmail={setOdeslatEmail}></EmailDialog>
        </div>
    );

}